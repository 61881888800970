import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VeeValidate from "vee-validate";
import { IconsPlugin } from "bootstrap-vue";
import VueSimpleAlert from "vue-simple-alert";
import { BootstrapVue } from "bootstrap-vue";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(VueSimpleAlert);

Vue.use(IconsPlugin);
Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
