import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://takeco.online:4005/api/test/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }
  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }
  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }

  getHighspeed() {
    return axios.get(API_URL + "highspeed", { headers: authHeader() });
  }
  getNewHighspeed() {
    return axios.get(API_URL + "new_highspeed", { headers: authHeader() });
  }
  getBookHighspeed() {
    return axios.get(API_URL + "book_highspeed", { headers: authHeader() });
  }

  getFrictionsaw() {
    return axios.get(API_URL + "frictionsaw", { headers: authHeader() });
  }
  getNewFrictionsaw() {
    return axios.get(API_URL + "new_frictionsaw", { headers: authHeader() });
  }
  getBookFrictionsaw() {
    return axios.get(API_URL + "book_frictionsaw", { headers: authHeader() });
  }

  getCarbide() {
    return axios.get(API_URL + "carbide", { headers: authHeader() });
  }
  getNewCarbide() {
    return axios.get(API_URL + "new_carbide", { headers: authHeader() });
  }
  getBookCarbide() {
    return axios.get(API_URL + "book_carbide", { headers: authHeader() });
  }

  getBandsaw() {
    return axios.get(API_URL + "bandsaw", { headers: authHeader() });
  }
  getBookBandsaw() {
    return axios.get(API_URL + "book_bandsaw", { headers: authHeader() });
  }
  getSetting() {
    return axios.get(API_URL + "setting", { headers: authHeader() });
  }
}
export default new UserService();
