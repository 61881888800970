import AuthService from "../services/auth.service";
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  //state: {status, user}
  state: initialState,
  //actions: {login, logout, register}
  actions: {
    //Action Login
    login({ commit }, user) {
      // console.log(
      //   "auth.module.js store : " + " " + user.username,
      //   user.password
      // )
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },

    //Action Logout
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },

    //Action register
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFilure");
          return Promise.reject(error);
        }
      );
    },
  },

  //mutations: {loginSuccess, loginFailure, logout, registerSuccess, registerFailure}
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },

    loginFailure(state, user) {
      state.status.loggedIn = false;
      state.user = user;
    },

    logout(status, user) {
      state.status.loggedIn = false;
      state.user = user;
    },

    registerSuccess(state, user) {
      state.status.loggedIn = false;
      state.user = user;
    },

    registerFailure(state, user) {
      state.status.loggedIn = false;
      state.user = user;
    },
  },
};
