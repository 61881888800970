<template>
  <b-navbar toggleable type="dark" variant="primary">
    <b-icon
      icon="justify"
      aria-hidden="true"
      v-b-toggle.sidebar-backdrop
      variant="light "
      font-scale="1.5"
    ></b-icon>

    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="dark"
      text-variant="light"
    >
      <template #default="{ hide }">
        <div class="px-3 py-2">
          <div
            class="offcanvas offcanvas-end text-bg-primary"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <!--Hello your name -->
            <div class="offcanvas-header">
              <h5
                class="offcanvas-title"
                id="offcanvasNavbarLabel"
                v-if="currentUser"
              >
                สวัสดี
                <!-- <b-icon
                  icon="emoji-laughing"
                  aria-hidden="true"
                  animation="throb"
                ></b-icon> -->
                :
                <i class="bi bi-person-fill"></i>
                {{ currentUser.username }}
              </h5>
            </div>
            <hr />
            <!-- list menu -->
            <div class="offcanvas-body" v-if="currentUser">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <!-- <li class="nav-item" v-if="currentUser">
                  <router-link
                    class="nav-link active"
                    aria-current="page"
                    to="/"
                  >
                    หน้าหลัก</router-link
                  >
                </li> -->
                <li v-if="showAdminBoard" class="nav-item">
                  <router-link to="/admin" class="nav-link active">
                    หน้ารวม แอดมิน</router-link
                  >
                </li>
                <li v-if="showModeratorBoard" class="nav-item active">
                  <router-link to="/mod" class="nav-link"
                    >Moderator Board</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    v-if="currentUser"
                    to="/user"
                    class="nav-link active"
                  >
                    แดชบอร์ด</router-link
                  >
                </li>

                <!-- Highspeed list menu -->
                <li class="nav-item dropdown" v-if="currentUser">
                  <b-nav-item-dropdown text="Highspeed" right>
                    <b-dropdown-item to="/new_highspeed"
                      ><b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                      New Highspeed</b-dropdown-item
                    >
                    <b-dropdown-item to="/highspeed"
                      ><b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                      Highspeed</b-dropdown-item
                    >
                    <hr />
                    <b-dropdown-item to="/book_highspeed">
                      Book Highspeed</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </li>
                <!-- Bandsaw list menu -->
                <li class="nav-item dropdown" v-if="currentUser">
                  <b-nav-item-dropdown text="Bandsaw" right>
                    <b-dropdown-item to="/bandsaw"
                      ><b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                      Bandsaw</b-dropdown-item
                    >
                    <hr />
                    <b-dropdown-item to="/book_bandsaw">
                      Book Bandsaw</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </li>
                <!-- Carbide list menu -->
                <li class="nav-item dropdown" v-if="currentUser">
                  <b-nav-item-dropdown text="Carbide" right>
                    <b-dropdown-item to="/new_carbide"
                      ><b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                      New Carbide</b-dropdown-item
                    >
                    <b-dropdown-item to="/carbide"
                      ><b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                      Carbide</b-dropdown-item
                    >
                    <hr />
                    <b-dropdown-item to="/book_carbide">
                      Book Carbide</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </li>
                <!-- Frictionsaw list menu -->
                <li class="nav-item dropdown" v-if="currentUser">
                  <b-nav-item-dropdown text="Frictionsaw" right>
                    <b-dropdown-item to="/new_frictionsaw"
                      ><b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                      New Frictionsaw</b-dropdown-item
                    >
                    <b-dropdown-item to="/frictionsaw"
                      ><b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                      Frictionsaw</b-dropdown-item
                    >
                    <hr />
                    <b-dropdown-item to="/book_frictionsaw">
                      Book Frictionsaw</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </li>
                <li class="nav-item">
                  <router-link
                    v-if="currentUser"
                    to="/setting"
                    class="nav-link active"
                  >
                    การตั้งค่า</router-link
                  >
                </li>
              </ul>
              <hr />
              <div>
                <div v-if="currentUser" class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <b-button size="sm" variant="danger" class="mb-2" block>
                      <a class="nav-link active" href @click="logOut">
                        <strong>ออกจากระบบ</strong>
                      </a>
                    </b-button>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <!-- Sign Up and login -->
          <nav class="mb-3" v-if="!currentUser">
            <b-nav vertical>
              <!-- <b-nav-item active @click="hide" to="/register"
                >Sign Up</b-nav-item
              > -->
              <b-nav-item to="/login" @click="hide">Login</b-nav-item>
            </b-nav>
          </nav>
        </div>
      </template> </b-sidebar
    ><b-navbar-brand href="/user"
      ><strong>TAKECO</strong>
      <sup class="production" v-if="currentUser"> Production</sup>
      <sub v-if="currentUser"><b> v.2</b> </sub></b-navbar-brand
    >
  </b-navbar>
</template>
<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_MODERATOR");
      }
      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  components: {},
};
</script>
<style>
.production {
  font-size: 12px;
}
</style>
