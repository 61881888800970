import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    // component: Home,
    component: () => import("../views/BoardUser.vue"),
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/registerproductionstakeco",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    //lazy-loaded
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    //lazy-loaded
    component: () => import("../views/BoardAdmin.vue"),
  },
  {
    path: "/mod",
    name: "moderator",
    //lazy-loaded
    component: () => import("../views/BoardModerator.vue"),
  },
  {
    path: "/user",
    name: "user",
    //lazy-loaded
    component: () => import("../views/BoardUser.vue"),
  },
  {
    path: "/setting",
    name: "setting",
    //lazy-loaded
    component: () => import("../views/Setting.vue"),
  },
  {
    path: "/highspeed",
    name: "highspeed",
    //lazy-loaded
    component: () => import("../views/Highspeed/Highspeed.vue"),
  },
  {
    path: "/new_highspeed",
    name: "new_highspeed",
    //lazy-loaded
    component: () => import("../views/Highspeed/New_highspeed.vue"),
  },
  {
    path: "/book_highspeed",
    name: "book_highspeed",
    //lazy-loaded
    component: () => import("../views/Highspeed/Book_highspeed.vue"),
  },
  {
    path: "/frictionsaw",
    name: "frictionsaw",
    //lazy-loaded
    component: () => import("../views/Frictionsaw/Frictionsaw.vue"),
  },
  {
    path: "/new_frictionsaw",
    name: "new_frictionsaw",
    //lazy-loaded
    component: () => import("../views/Frictionsaw/New_frictionsaw.vue"),
  },
  {
    path: "/book_frictionsaw",
    name: "book_frictionsaw",
    //lazy-loaded
    component: () => import("../views/Frictionsaw/Book_frictionsaw.vue"),
  },
  {
    path: "/carbide",
    name: "carbide",
    //lazy-loaded
    component: () => import("../views/Carbide/Carbide.vue"),
  },
  {
    path: "/new_carbide",
    name: "new_carbide",
    //lazy-loaded
    component: () => import("../views/Carbide/New_carbide.vue"),
  },
  {
    path: "/book_carbide",
    name: "book_carbide",
    //lazy-loaded
    component: () => import("../views/Carbide/Book_carbide.vue"),
  },
  {
    path: "/bandsaw",
    name: "bandsaw",
    //lazy-loaded
    component: () => import("../views/Bandsaw/Bandsaw.vue"),
  },
  {
    path: "/book_bandsaw",
    name: "book_bandsaw",
    //lazy-loaded
    component: () => import("../views/Bandsaw/Book_bandsaw.vue"),
  },
  {
    path: "/highspeed/list_history_highspeed",
    name: "list_history_highspeed",
    //lazy-loaded
    component: () => import("@/components/List_history_highspeed.vue"),
  },
  {
    path: "/setting_bandsaw",
    name: "setting_bandsaw",
    //lazy-loaded
    component: () => import("../components/Setting_bandsaw.vue"),
  },
  {
    path: "/setting_highspeed",
    name: "setting_highspeed",
    //lazy-loaded
    component: () => import("../components/Setting_highspeed.vue"),
  },
  {
    path: "/setting_carbide",
    name: "setting_carbide",
    //lazy-loaded
    component: () => import("../components/Setting_carbide.vue"),
  },
  {
    path: "/setting_frictionsaw",
    name: "setting_frictionsaw",
    //lazy-loaded
    component: () => import("../components/Setting_frictionsaw.vue"),
  },
  {
    path: "/highspeed/print_highspeed",
    name: "print_highspeed",
    //lazy-loaded
    component: () => import("../components/Print_highspeed.vue"),
  },
  {
    path: "/bandsaw/print_bandsaw",
    name: "print_bandsaw",
    //lazy-loaded
    component: () => import("../components/Print_bandsaw.vue"),
  },
  {
    path: "/carbide/print_carbide",
    name: "print_carbide",
    //lazy-loaded
    component: () => import("../components/Print_carbide.vue"),
  },
  {
    path: "/frictionsaw/print_frictionsaw",
    name: "print_frictionsaw",
    //lazy-loaded
    component: () => import("../components/Print_frictionsaw.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});
